import { defer } from "@shopify/remix-oxygen"
import { useLoaderData } from "@remix-run/react"
import { ILoaderArgs } from "~/types"
import { getPagTemplateeMetaObject } from "./getPageTemplateMetaObjects"
import renderPageSections from "./renderPageSections"
import { CMS } from "../../sections/CMS"

export async function loader({ context, request }: ILoaderArgs) {
	// console.log("start of index loader")
	const url = new URL(request.url)
	// TODO: better understand how to handle non-defined index routes like /non-existing-page
	if (
		url.pathname !== "/" &&
		url.pathname !== `${context.storefront.i18n.pathPrefix}/`
	) {
		throw new Response("Not found", { status: 404 })
	}

	const { metaobject, deferrable, parsedSections } = await getPagTemplateeMetaObject({
		cache: context.cache,
		req: request,
		handle: "homepage"
	})
	return defer({
		metaobject,
		parsedSections,
		...deferrable
	})
}

export default function Home() {
	const data = useLoaderData<typeof loader>()

	return renderPageSections({
		data: data,
		sections: data.parsedSections as CMS.ParsedSections
	})
}
